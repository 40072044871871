import styles from './ErrorBoundary.styl'
import { Icon, Link } from '@nike/eds'
import React from 'react'

const platformConsoleSlackLink = 'https://nikedigital.slack.com/archives/CC5FN7AMV'

type ErrorBoundaryProps = {
  fullPage?: boolean
  name?: string
}

type ErrorBoundaryState = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, message: ((error || {}) as Error).message }
  }

  componentDidCatch(error: unknown, errorInfo: {}) {
    console.error(error)
    // TODO: Nark ?
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={`${styles.errorWrapper} ${this.props.fullPage ? styles.fullPage : ''}`}>
          {this.props.fullPage && (
            <h1 className={styles.heading}>Oops...something isn&apos;t quite right</h1>
          )}
          <p className={styles.text}>
            An issue occurred with this component, please refresh the page. If the error happens
            again, please contact <Link href={platformConsoleSlackLink}>#platformconsole</Link>.
          </p>
          {this.props.fullPage && <Icon size='l' className={styles.swoosh} name='NikeSwoosh' />}
        </div>
      )
    }

    return this.props.children
  }
}

export const withErrorBoundary =
  (Component: (props: any) => JSX.Element, fullPage = false) =>
  (props: unknown) =>
    (
      <ErrorBoundary fullPage={fullPage}>
        <Component {...(props as {})} />
      </ErrorBoundary>
    )
